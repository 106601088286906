import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import { AuthorsWithTooltip } from 'views/blog-post/components/authors-with-tooltip';
import { HUB_TAGS_DISPLAY } from 'views/single-blog-genre/constants';
import { HeaderThird } from 'components/header-third';
import { hoverStyles, LabelWithArrow } from 'components/label-with-arrow';
import { Paragraph } from 'components/paragraph';
import { gaTrackedEvents } from 'constants/ga-tracked-events';
import { CONSTANTS } from 'constants/styles/constants';
import dayjs from 'dayjs';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { bool, object, shape, string } from 'prop-types';
import { getBlogPostHubs, getBlogPostUrl } from 'utilities/contentful';
import { trackCustomEventGoogleAnalytics } from 'utilities/index';

const SLink = styled(Link)`
    text-decoration: none;
    color: var(--black-text-color);
    ${hoverStyles};

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
`;

const SBlogPost = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;

    ${CONSTANTS.MEDIA.min768`
         ${({ isHorizontal }) =>
             isHorizontal &&
             css`
                 display: grid;
                 grid-template-columns: 1fr 1fr;
                 gap: 1rem;
             `}
    `}
`;

const SWrapper = styled.div`
    display: flex;
    align-items: center;
    margin: 0.75rem 0 0.25rem;
`;

const SPhotoContainer = styled.div`
    position: relative;
    max-height: 26.25rem;
    ${({ isMain }) =>
        isMain &&
        css`
            max-height: 33.75rem;
        `}
`;

const SDate = styled(Paragraph)`
    font-size: 0.75rem;
    margin-right: 0.5rem;
`;

const STitle = styled(HeaderThird)`
    font-size: 1.75rem;
    font-weight: bold;
    line-height: 1.3;
    ${({ isMain }) =>
        isMain &&
        css`
            font-size: 2.25rem;
        `}
    ${({ isSmallerTitle }) =>
        isSmallerTitle &&
        css`
            font-size: 1.375rem;
        `}
`;

const SLabelWithArrow = styled(LabelWithArrow)`
    margin-top: 2rem;
`;

const SDescription = styled(Paragraph)`
    margin-top: 1.5rem;
`;

const SPostContent = styled.div`
    display: flex;
    flex-direction: column;
`;

const STagsWrapper = styled.div`
    display: flex;
    gap: 0.5rem;
    position: absolute;
    bottom: 1rem;
    left: 1rem;
`;

const STag = styled.div`
    background: var(--primary-color-90);
    padding: 0.75rem 1rem;
    border-radius: 2.25rem;
    font-size: 0.875rem;
`;

export const BlogPostLink = ({
    blogPost,
    withDescription,
    isMain,
    isSmallerTitle,
    isHorizontal,
    withTags,
    hub,
    isPressRelease,
}) => {
    const { formatMessage } = useIntl();

    const {
        title,
        image,
        thumbnail,
        shortDescription,
        altThumbnailDescription,
        multipleAuthors,
        updatedAt,
        date,
    } = blogPost;
    const blogPostImage = getImage(image);
    const blogPostThumbnail = getImage(thumbnail);
    const displayedDate = dayjs(updatedAt || date).format('DD.MM.YYYY');
    const hubs = getBlogPostHubs(blogPost);
    const blogPostUrl = getBlogPostUrl(blogPost, hub, isPressRelease);
    const imageSource = isMain
        ? blogPostImage
        : blogPostThumbnail || blogPostImage;

    const handleClick = () => {
        trackCustomEventGoogleAnalytics(gaTrackedEvents.NEWS.ARTICLE);
    };

    return (
        <SBlogPost isHorizontal={isHorizontal}>
            {/* temporarily making image optional, will change it when styling blog page according to the latest designs */}
            {imageSource && (
                <SPhotoContainer isMain={isMain}>
                    <GatsbyImage
                        image={imageSource}
                        alt={
                            shortDescription?.internal?.content ||
                            altThumbnailDescription ||
                            `article thumbnail - ${title}`
                        }
                    />
                    {withTags && (
                        <STagsWrapper>
                            {hubs.map((tag) => (
                                <STag key={tag}>
                                    <FormattedMessage
                                        id={HUB_TAGS_DISPLAY[tag]}
                                    />
                                </STag>
                            ))}
                        </STagsWrapper>
                    )}
                </SPhotoContainer>
            )}
            <SPostContent isHorizontal={isHorizontal}>
                <SWrapper>
                    <SDate>{displayedDate}</SDate>
                    <AuthorsWithTooltip authors={multipleAuthors} />
                </SWrapper>
                <STitle isSmallerTitle={isSmallerTitle} isMain={isMain}>
                    {title}
                </STitle>
                {withDescription && shortDescription?.internal?.content && (
                    <SDescription>
                        {shortDescription?.internal?.content}
                    </SDescription>
                )}
                <SLink
                    to={blogPostUrl}
                    rel="dofollow"
                    aria-label={`${formatMessage({
                        id: 'general.readMoreAbout',
                    })} ${title}`}
                    onClick={handleClick}
                >
                    <SLabelWithArrow label="general.readFullArticle" />
                </SLink>
            </SPostContent>
        </SBlogPost>
    );
};

BlogPostLink.propTypes = {
    blogPost: shape({
        createdAt: string,
        date: string,
        title: string,
        link: string,
        image: object,
        thumbnail: object,
    }).isRequired,
    withDescription: bool,
    isMain: bool,
    isSmallerTitle: bool,
    isHorizontal: bool,
    withTags: bool,
    hub: string,
    isPressRelease: bool,
};

BlogPostLink.defaultProps = {
    withDescription: true,
    isMain: false,
    isSmallerTitle: false,
    isHorizontal: false,
    withTags: false,
    isPressRelease: false,
};
